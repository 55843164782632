module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.gaviscon.co.kr/","stripQueryString":true},
    },{
      plugin: require('../../gatsby-source-umbraco-phoenix/node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"/home/vsts/work/1/s/packages/gatsby-source-umbraco-phoenix/.gatsby","rootPath":"/home/vsts/work/1/s/packages/gatsby-source-umbraco-phoenix","baseURL":"https://phx-gaviscon-kr-prod.husky-2.rbcloud.io","xOrigin":["https://phx-gaviscon-kr-prod.husky-2.rbcloud.io"],"ldsURL":"http://digital-lds-application-web-production.frankfurt.rbdigitalcloud.com","params":{"salsify":{"url":"https://app.salsify.com","orgId":"s-3ec6a81f-e07f-4078-b4cb-1a7206b985e9","authToken":"4e1d3280bdd37e291b0cbe28fe34f56f33d6da9b7a4d74d77562a72cb49a65a1"}}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WGW7FHG","includeInDevelopment":false,"timeout":2000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../../gatsby-theme-gaviscon/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
